import * as React from 'react';
import YelpReviews from 'bundles/App/pages/HomePage/Wow/YelpReviews';
import GoogleReviews from 'bundles/App/pages/HomePage/Wow/GoogleReviews';
import FacebookReviews from 'bundles/App/pages/HomePage/Wow/FacebookReviews';
import FeefoReviews from 'bundles/App/pages/HomePage/Wow/FeefoReviews';

const Wow = () => (
  <>
    <div className="relative mx-auto flex w-full justify-center px-1 md:px-3 lg:max-w-2xl">
      <h2
        className="m-auto !font-hvBold !text-6xl 
                !leading-[72px] !-tracking-[3px] lg:!text-9xl  
                 lg:!leading-[154px] lg:!tracking-normal -md:my-12"
      >
        &ldquo;Wow!&rdquo;
      </h2>
    </div>
    <div className="flex w-full justify-center">
      <FeefoReviews />
    </div>
    <div className="mx-auto sm:grid sm:grid-cols-1 md:hidden md:grid-cols-3 lg:grid lg:w-full lg:max-w-4xl">
      <FacebookReviews showText="none" />
      <GoogleReviews showText="none" />
      <YelpReviews showText="none" />
    </div>
  </>
);

export default Wow;
