import * as React from 'react';
import { Helmet } from 'react-helmet-async';

const FeefoReviews = () => (
  <>
    <Helmet
      style={[
        {
          cssText: `
            .feefowidget-star {
              color: #00aeef !important;
            }
            .feefowidget-right-button {
              background-color: black !important;
              color: white !important;
              margin-top: 46px !important;
              height: 36px !important;
              width: 36px !important;
              border-radius: 50% !important;
            }
          `,
        },
      ]}
    >
      <script src="https://api.feefo.com/api/javascript/mgx-copy" async />
    </Helmet>
    <div
      id="feefo-service-review-carousel-widgetId"
      className="feefo-review-carousel-widget-service hidden sm:block"
    />
    <div id="feefo-service-review-widgetId" className="feefo-review-widget-service block sm:hidden" />
  </>
);

export default FeefoReviews;
